import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import twitterLogo from "../images/twitter.svg"
import linkedinLogo from "../images/linkedin.svg"
import instagramLogo from "../images/instagram.svg"
import githubLogo from "../images/github.svg"


const IconPack=(props)=>{
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        social {
                            twitter
                            linkedin
                            instagram
                            github
                        }
                    }
                }
            }`
        )
    const social = data.site.siteMetadata.social

    let classes = "IconPack "
    if(props.color){
        classes+="IconPack-white "
    }
    console.log(props)
    return(
        <div className={classes}>
            <a href={`https://twitter.com/${social.twitter}`} target="_blank" rel="noreferrer"><img src={twitterLogo} alt="Logo"/></a>
            <a href={`https://www.linkedin.com/in/${social.linkedin}`} target="_blank" rel="noreferrer"><img src={linkedinLogo} alt="Logo"/></a>
            <a href={`https://www.instagram.com/${social.instagram}`} target="_blank" rel="noreferrer"><img src={instagramLogo} alt="Logo"/></a>
            <a href={`https://github.com/${social.github}`} target="_blank" rel="noreferrer"><img src={githubLogo} alt="Logo"/></a>
        </div>
    )
}

export default IconPack