import React from 'react'
import IconPack from './iconpack'
import Logo from '../images/logo.svg'

const Footer =(props)=>{
    if(props.isBlogPage){
        return null
    }
    if(props.isRootPath){
        return(
            <div className="FooterHome">
                <div className="container">
                    <div className="row content">
                        <div className="part-1 col-md-3 col-12 p-0">
                            <h1 className="heading">Say Hi.</h1>
                            <p className="message">Send me a message on roshanrjv.27@gmail.com</p>
                            <IconPack color="white"/>
                        </div>
                        <div className="part-2 col-md-6 offset-md-3 col-12 p-0">
                            <div className="part-2-content">
                                <img src={Logo} alt="logo" className="logo"/>
                                <p>Designed and Developed by Roshan R</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return(
        <div className="Footer">
            <div className="container">
                <div className="content">
                    <div className="flex">
                        <img src={Logo} alt="Logo" className="logo"/>
                        <p>Designed and Developed by Roshan R</p>
                    </div>
                    <div className="icons">
                        <IconPack color="white"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer
