import React from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'

const Contact = ({location})=>{
    return(
        <>
            <Header/>
            <SEO title="Contact"/>
            <div className="Contact">
                <div className="Contact-flex-grow">
                    <div className="ContactComponent">
                        <div className="container">
                            <div className="content">
                                <div className="inner-container">
                                    <h1 className="heading">Contact Me</h1>
                                    <p className="paragraph">You can contact me by <a href="mailto:roshanrjv.27@gmail.com">e-mail</a> or <a href="https://www.linkedin.com/in/roshanrjv/" target="_blank" rel="noreferrer">linkedin</a> to say hi!.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Contact